import { createSlice } from "@reduxjs/toolkit";

export const getEZBetslipCacheInitialState = (betslipOutcomeInitialPrices = {}) => ({
  betslipOutcomeInitialPrices,
  popupInfo: {},
});

const ezCacheSlice = createSlice({
  extraReducers: () => {
    //
  },
  initialState: getEZBetslipCacheInitialState({}),
  name: "ezCache",
  reducers: {
    persistLatestOutcomePrices(state, action) {
      state.betslipOutcomeInitialPrices = action.payload.betslipOutcomeInitialPrices;
    },
    updatePopupInfo(state, action) {
      state.popupInfo = { ...state.popupInfo, ...action.payload };
    },
  },
});

const { actions, reducer } = ezCacheSlice;
export const { persistLatestOutcomePrices, updatePopupInfo } = actions;
export default reducer;
