import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import originalAxios from "axios";
import merge from "lodash.merge";

import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";
import { getAuthPriceFormat } from "../reselect/auth-selector";

export const getInitialState = (couponData = {}, asianCouponData = {}, vaixCouponData = {}, vaixOPCouponData = {}) => ({
  activeSearchKeyword: null,
  asianCouponData,
  asianCouponError: {},
  asianCouponFromTimestamp: {},
  asianCouponLoading: {},
  couponData, // keep a map of all data per search code (events and paths)
  couponError: {},
  couponFromTimestamp: {}, // keep a map of all timestamps per search code (events and paths)
  couponLoading: {},
  // keep a map of all loading states per search code (events and paths)
  searchCouponData: null,

  searchError: null,

  searchFromTimestamp: null,

  searchLoading: false,

  vaixCouponData, // keep a map of all data per search code (events and paths)

  // keep a map of all loading states per search code (events and paths)
  vaixCouponError: {},

  vaixCouponFromTimestamp: {},
  // keep a map of all timestamps per search code (events and paths)
  vaixCouponLoading: {},
  vaixIds: {},

  vaixOPCouponData, // keep a map of all data per search code (events and paths)

  // keep a map of all loading states per search code (events and paths)
  vaixOPCouponError: {},

  vaixOPCouponFromTimestamp: {},
  // keep a map of all timestamps per search code (events and paths)
  vaixOPCouponLoading: {},
});

function getCouponCodesForTracking(isCmsMarketTypeFilterOn, isFeatured, isBetBuilder, codes) {
  if (isBetBuilder) {
    return `betbuilder-${codes}`;
  }
  if (isCmsMarketTypeFilterOn) {
    return `cms-${codes}`;
  }
  if (isFeatured) {
    return `featured-${codes}`;
  }

  return codes;
}

function getCouponCodesFromArgs(args) {
  const { betBuilder, cmsMarketFilter, codes, featured } = args;

  if (betBuilder) {
    return `betbuilder-${codes}`;
  }
  if (featured) {
    return `featured-${codes}`;
  }
  if (cmsMarketFilter) {
    return `cms-${codes}`;
  }

  return codes;
}

function prepareParams(originId, lineId, data) {
  let params = `originId=${originId}&lineId=${lineId}`;

  const eventType = data["eventType"] ? data["eventType"] : null;
  if (eventType) params += `&eventType=${eventType}`;

  const allMarkets = data["allMarkets"];
  if (allMarkets) params += `&ext=1`;

  const live = data["live"];
  if (live) params += `&live=1`;

  const virtual = data["virtual"];
  if (virtual) params += `&virtual=${virtual}`;

  const icon = data["icon"];
  if (icon) params += `&icon=${icon}`;

  if (!data["betBuilder"]) {
    if (data["codes"] && data["couponFromTimestamp"]) {
      const from = data["codes"] ? (data["couponFromTimestamp"] ? data["couponFromTimestamp"] : null) : null;
      if (from) params += `&from=${from}`;
    } else if (data["searchFromTimestamp"]) {
      const from = data["searchFromTimestamp"];
      if (from) params += `&from=${from}`;
    }
  }

  const fromDate = data["fromDate"];
  if (fromDate) params += `&fromDate=${fromDate}`;

  const toDate = data["toDate"];
  if (toDate) params += `&toDate=${toDate}`;

  const marketTypeGroups = data["marketTypeGroups"];
  if (marketTypeGroups) params += `&marketTypeGroups=${marketTypeGroups}`;

  const shortNames = data["shortNames"]; // shortnames is true/false
  if (shortNames) params += `&shortNames=1`;

  // const count = data["count"];
  // if (count) params += `&count=${count}`;

  const keywordSearch = data["keywordSearch"];
  if (keywordSearch) params += `&keywordSearch=${keywordSearch}`;

  const compactSpread = data["compactSpread"];
  if (compactSpread) params += `&compactSpread=true`;

  const cmsMarketFilter = data["cmsMarketFilter"];
  if (cmsMarketFilter) params += `&cmsMarketFilter=true`;

  const featured = data["featured"];
  if (featured) params += `&featured=true`;

  const allowMultiMarket = data["allowMultiMarket"];
  if (allowMultiMarket) params += `&allowMultiMarket=true`;

  const betBuilder = data["betBuilder"];
  if (betBuilder) params += `&betBuilder=true`;

  const betBuilderSelections = data["betBuilderSelections"];
  if (betBuilderSelections) params += `&betBuilderSelections=${betBuilderSelections}`;

  return params;
}

function formatCouponData(data) {
  // Notice this method modifies the original response objects. We are not performing a deep clone for performance reasons, but it can be done in the future if this causes trouble with the logic.
  const couponData = {}; // change the data structure to make it hierarchical and easier to render later on in the components

  for (const [key, item] of Object.entries(data["items"])) {
    item["id"] = parseInt(key.substring(1, key.length), 10);
    item["type"] = key.substring(0, 1);
    switch (item["type"]) {
      case "m":
        item["open"] = !(item["flags"] && (item["flags"].includes("suspended") || item["flags"].includes("closed")));
        item["reserve"] = item["flags"] && (item["flags"].includes("reserved") || item["flags"].includes("Reserve"));
        break;
      case "o":
        item["hidden"] = !!(item["flags"] && (item["flags"].includes("hidden") || item["flags"].includes("suspended")));
        delete item["flags"]; // does not play well with merges in the reducer...
        break;
      default:
        break;
    }

    if (item["parent"] === undefined || item["parent"] === null || item["parent"] === "p0") {
      // add a root element
      couponData[key] = item;
    } else {
      const parentItem = data["items"][item["parent"]];
      if (!parentItem["children"]) {
        parentItem["children"] = {};
      }
      parentItem["children"][key] = item;
    }
  }

  return couponData;
}

const couponCancelToken = {};

export const loadCouponData = createAsyncThunk("coupon/loadCouponData", async (data, thunkAPI) => {
  try {
    const { authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const priceFormat = getAuthPriceFormat(thunkAPI.getState());

    const isCmsMarketTypeFilterOn = !!data["cmsMarketFilter"];
    const isFeatured = !!data["featured"];
    const isBetBuilder = !!data["betBuilder"];

    const codes = data["codes"];
    const codesForTracking = getCouponCodesForTracking(isCmsMarketTypeFilterOn, isFeatured, isBetBuilder, codes);

    let thisCancelToken = null;
    // Check if there are any previous pending requests

    if (couponCancelToken[codesForTracking]) {
      // cancel the previous operation...
      couponCancelToken[codesForTracking].cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    couponCancelToken[codesForTracking] = originalAxios.CancelToken.source();
    thisCancelToken = couponCancelToken[codesForTracking];

    const couponFromTimestamp = thunkAPI.getState().coupon.couponFromTimestamp[codesForTracking];
    const params = prepareParams(originId, lineId, {
      ...data,
      couponFromTimestamp,
    });

    const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });
    if (priceFormat) {
      axios.defaults.headers["x-priceformat"] = priceFormat;
    }

    let result = null;
    if (data["count"]) {
      const nextCount = data["count"];

      result = await axios.get(`/player/next/${nextCount}/${codes}?${params}`, {
        cancelToken: thisCancelToken.token,
      }); // Pass the cancel token to the current request
    } else {
      result = await axios.get(`/player/sdc/${codes}?${params}`, { cancelToken: thisCancelToken.token }); // Pass the cancel token to the current request
    }

    delete couponCancelToken[codesForTracking];

    return {
      cmsMarketFilterOnMode: isCmsMarketTypeFilterOn,
      couponData: formatCouponData(result.data),
      couponFromTimestamp: result.data["toTimestamp"],
      fromTimestampQuery: isBetBuilder ? 0 : couponFromTimestamp,
      locked: (!!data.virtual && result.data.virtualLocked) || (!data.virtual && result.data.sportsLocked),
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load coupon data", // serializable (err.response.data)
      name: "Load Coupon Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const asianCouponCancelToken = {};

export const loadAsianCouponData = createAsyncThunk("coupon/loadAsianCouponData", async (data, thunkAPI) => {
  try {
    const { authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const priceFormat = getAuthPriceFormat(thunkAPI.getState());

    const isCmsMarketTypeFilterOn = !!data["cmsMarketFilter"];
    const isFeatured = !!data["featured"];

    const codes = data["codes"];
    const codesForTracking = isCmsMarketTypeFilterOn ? `cms-${codes}` : isFeatured ? `featured-${codes}` : codes;
    const sportCode = data["sportCode"];

    let thisCancelToken = null;
    // Check if there are any previous pending requests

    if (asianCouponCancelToken[codesForTracking]) {
      // cancel the previous operation...
      asianCouponCancelToken[codesForTracking].cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    asianCouponCancelToken[codesForTracking] = originalAxios.CancelToken.source();
    thisCancelToken = asianCouponCancelToken[codesForTracking];

    const couponFromTimestamp = thunkAPI.getState().coupon.couponFromTimestamp[codesForTracking];
    const params = prepareParams(originId, lineId, {
      ...data,
      couponFromTimestamp,
    });

    const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });
    if (priceFormat) {
      axios.defaults.headers["x-priceformat"] = priceFormat;
    }

    let result = null;
    if (data["count"]) {
      const nextCount = data["count"];

      result = await axios.get(`/player/next/${nextCount}/${codes.split("/")[0]}/${codes.split("/")[1]}?${params}`, {
        cancelToken: thisCancelToken.token,
      }); // Pass the cancel token to the current request
    } else {
      result = await axios.get(`/player/sdc/acoupon/${codes.split("/")[0]}/${codes.split("/")[1]}?${params}`, {
        cancelToken: thisCancelToken.token,
      }); // Pass the cancel token to the current request
    }

    delete asianCouponCancelToken[codesForTracking];

    return {
      couponData: formatCouponData(result.data),
      couponFromTimestamp: result.data["toTimestamp"],
      fromTimestampQuery: couponFromTimestamp,
      locked: (!!data.virtual && result.data.virtualLocked) || (!data.virtual && result.data.sportsLocked),
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load coupon data", // serializable (err.response.data)
      name: "Load Coupon Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

let searchCancelToken = null;
export const searchForCouponData = createAsyncThunk("coupon/searchCouponData", async (data, thunkAPI) => {
  try {
    const { authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const priceFormat = getAuthPriceFormat(thunkAPI.getState());

    let thisCancelToken = null;
    // Check if there are any previous pending requests
    if (searchCancelToken) {
      // cancel the previous operation...
      searchCancelToken.cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    searchCancelToken = originalAxios.CancelToken.source();
    thisCancelToken = searchCancelToken;

    const searchFromTimestamp = thunkAPI.getState().coupon.searchFromTimestamp;

    // Bypass the live flag in the prepareParams, as we have a mismatch of int vs boolean in the endpoints
    const live = data.live;
    data.live = undefined;

    let params = prepareParams(originId, lineId, { ...data, searchFromTimestamp });

    const american = data["american"];
    if (american) params += `&american=true`;

    params += `&live=${!!live}`;

    const keyword = data["keyword"];

    const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });
    if (priceFormat) {
      axios.defaults.headers["x-priceformat"] = priceFormat;
    }

    const result = await axios.get(`/player/sdc/search/${keyword}?${params}`, { cancelToken: thisCancelToken.token }); // Pass the cancel token to the current request

    const couponData = formatCouponData(result.data);

    return {
      activeSearchKeyword: keyword,
      locked: (!!data.virtual && result.data.virtualLocked) || (!data.virtual && result.data.sportsLocked),
      searchCouponData: couponData,
      searchFromTimestamp: result.data["toTimestamp"],
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load coupon data", // serializable (err.response.data)
      name: "Load Coupon Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const vaixCouponCancelToken = {};

export const loadVaixCouponData = createAsyncThunk("vaixCoupon/loadVaixCouponData", async (data, thunkAPI) => {
  try {
    const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const priceFormat = getAuthPriceFormat(thunkAPI.getState());

    let thisCancelToken = null;
    // Check if there are any previous pending requests

    const vaixCode = `vaix-${data.mode}`;

    if (vaixCouponCancelToken[vaixCode]) {
      // cancel the previous operation...
      vaixCouponCancelToken[vaixCode].cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    vaixCouponCancelToken[vaixCode] = originalAxios.CancelToken.source();
    thisCancelToken = vaixCouponCancelToken[vaixCode];

    const vaixCouponFromTimestamp = thunkAPI.getState().coupon.vaixCouponFromTimestamp[vaixCode];
    let params = prepareParams(originId, lineId, {
      ...data,
    });

    if (vaixCouponFromTimestamp) {
      params += `&from=${vaixCouponFromTimestamp}`;
    }

    const count = data["count"];
    if (count) params += `&count=${count}`;

    const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });
    if (priceFormat) {
      axios.defaults.headers["x-priceformat"] = priceFormat;
    }

    let result;
    if (accountId && authToken) {
      result = await axios.get(
        `/player/acc/${accountId}/vaix/${data.mode === "EVENT" ? "events" : "leagues"}/recommended?${params}`,
        {
          cancelToken: thisCancelToken.token,
        },
      ); // Pass the cancel token to the current request
    } else {
      result = await axios.get(`/player/vaix/${data.mode === "EVENT" ? "events" : "leagues"}/popular?${params}`, {
        cancelToken: thisCancelToken.token,
      }); // Pass the cancel token to the current request;
    }

    delete vaixCouponCancelToken[vaixCode];

    return {
      fromTimestampQuery: vaixCouponFromTimestamp,
      locked: (!!data.virtual && result.data.virtualLocked) || (!data.virtual && result.data.sportsLocked),
      vaixCouponData: formatCouponData(result.data),
      vaixCouponFromTimestamp: result.data["toTimestamp"],
    };
  } catch (err) {
    console.log(err);
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load vaixCoupon data", // serializable (err.response.data)
      name: "Load vaixCoupon Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const vaixOPCouponCancelToken = {};

export const loadVaixOPCouponData = createAsyncThunk("vaixCoupon/loadOPCouponData", async (data, thunkAPI) => {
  try {
    const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const priceFormat = getAuthPriceFormat(thunkAPI.getState());

    const vaixCode = `vaix-${data.mode}`;

    let thisCancelToken = null;
    // Check if there are any previous pending requests

    if (vaixOPCouponCancelToken[vaixCode]) {
      // cancel the previous operation...
      vaixOPCouponCancelToken[vaixCode].cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    vaixOPCouponCancelToken[vaixCode] = originalAxios.CancelToken.source();
    thisCancelToken = vaixOPCouponCancelToken[vaixCode];

    const couponFromTimestamp = thunkAPI.getState().coupon.couponFromTimestamp[vaixCode];
    let params = prepareParams(originId, lineId, {
      ...data,
      couponFromTimestamp,
    });

    if (couponFromTimestamp) {
      params += `&from=${couponFromTimestamp}`;
    }

    const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });
    if (priceFormat) {
      axios.defaults.headers["x-priceformat"] = priceFormat;
    }

    let result;
    if (accountId && authToken) {
      result = await axios.get(
        `/player/acc/${accountId}/vaix/${data.mode === "EVENT" ? "events" : "leagues"}/recommended/acoupon/${
          data.criteriaSuffix
        }?${params}`,
        {
          cancelToken: thisCancelToken.token,
        },
      ); // Pass the cancel token to the current request
    } else {
      result = await axios.get(
        `/player/vaix/${data.mode === "EVENT" ? "events" : "leagues"}/popular/acoupon/${data.criteriaSuffix}?${params}`,
        {
          cancelToken: thisCancelToken.token,
        },
      ); // Pass the cancel token to the current request;
    }

    delete vaixOPCouponCancelToken[vaixCode];

    return {
      fromTimestampQuery: couponFromTimestamp,
      locked: (!!data.virtual && result.data.virtualLocked) || (!data.virtual && result.data.sportsLocked),
      vaixOPCouponData: formatCouponData(result.data),
      vaixOPCouponFromTimestamp: result.data["toTimestamp"],
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load coupon data", // serializable (err.response.data)
      name: "Load Coupon Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const vaixEventIdsCancelToken = {};

export const loadVaixEventIds = createAsyncThunk("vaixCoupon/loadVaixIds", async (data, thunkAPI) => {
  try {
    const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const priceFormat = getAuthPriceFormat(thunkAPI.getState());

    let thisCancelToken = null;
    // Check if there are any previous pending requests

    const vaixCode = `vaix-${data.mode}`;

    if (vaixEventIdsCancelToken[vaixCode]) {
      // cancel the previous operation...
      vaixEventIdsCancelToken[vaixCode].cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    vaixEventIdsCancelToken[vaixCode] = originalAxios.CancelToken.source();
    thisCancelToken = vaixEventIdsCancelToken[vaixCode];

    let params = prepareParams(originId, lineId, {
      ...data,
    });

    const count = data["count"];
    if (count) params += `&count=${count}`;

    const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });

    let result;
    if (accountId && authToken) {
      result = await axios.get(
        `/player/acc/${accountId}/vaix/${data.mode === "EVENT" ? "events" : "leagues"}/recommended/ids?${params}`,
        {
          cancelToken: thisCancelToken.token,
        },
      ); // Pass the cancel token to the current request
    } else {
      result = await axios.get(`/player/vaix/${data.mode === "EVENT" ? "events" : "leagues"}/popular/ids?${params}`, {
        cancelToken: thisCancelToken.token,
      }); // Pass the cancel token to the current request;
    }

    delete vaixEventIdsCancelToken[vaixCode];

    return {
      vaixIds: result.data,
    };
  } catch (err) {
    console.log(err);
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load vaixCoupon data", // serializable (err.response.data)
      name: "Load vaixCoupon Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const couponSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(loadAsianCouponData.pending, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.codes) {
          return;
        }
        const args = action.meta?.arg;
        const codes = args?.featured
          ? `featured-${args.codes}`
          : args?.cmsMarketFilter
            ? `cms-${args.codes}`
            : args?.codes;

        state.asianCouponLoading[codes] = true;
        state.asianCouponError[codes] = null;
      })
      .addCase(loadAsianCouponData.rejected, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.codes) {
          return;
        }
        const args = action.meta?.arg;
        const codes = args?.featured
          ? `featured-${args.codes}`
          : args?.cmsMarketFilter
            ? `cms-${args.codes}`
            : args?.codes;

        state.asianCouponLoading[codes] = false;
        state.asianCouponError[codes] = action.error.message;
      })
      .addCase(loadAsianCouponData.fulfilled, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.codes) {
          return;
        }
        const args = action.meta?.arg;
        const codes = args?.featured
          ? `featured-${args.codes}`
          : args?.cmsMarketFilter
            ? `cms-${args.codes}`
            : args?.codes;

        const fromTimestampQuery = action.payload.fromTimestampQuery;

        state.asianCouponLoading[codes] = false;

        if (action.payload.locked) {
          // if we are under lockdown, clear this out
          state.asianCouponData[codes] = {};
          state.asianCouponFromTimestamp[codes] = null;
        } else if (action.payload.couponData) {
          if (!state.asianCouponData[codes] || !fromTimestampQuery) {
            state.asianCouponData[codes] = action.payload.couponData;
          } else {
            state.asianCouponData[codes] = merge(state.couponData[codes], action.payload.couponData);
          }
          state.asianCouponFromTimestamp[codes] = action.payload.couponFromTimestamp;
        }
      })
      .addCase(loadVaixOPCouponData.pending, (state, action) => {
        const vaixCode = `vaix-${action.meta?.arg?.mode}`;
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!vaixCode) {
          return;
        }
        state.vaixOPCouponLoading[vaixCode] = true;
        state.vaixOPCouponError[vaixCode] = null;
      })
      .addCase(loadVaixOPCouponData.rejected, (state, action) => {
        const vaixCode = `vaix-${action.meta?.arg?.mode}`;
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!vaixCode) {
          return;
        }
        state.vaixOPCouponLoading[vaixCode] = false;
        state.vaixOPCouponError[vaixCode] = action.error.message;
      })
      .addCase(loadVaixOPCouponData.fulfilled, (state, action) => {
        const vaixCode = `vaix-${action.meta?.arg?.mode}`;
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!vaixCode) {
          return;
        }
        state.vaixOPCouponLoading[vaixCode] = false;

        if (action.payload.locked) {
          // if we are under lockdown, clear this out
          state.vaixOPCouponData[vaixCode] = {};
          state.vaixOPCouponFromTimestamp[vaixCode] = null;
        } else if (action.payload.vaixOPCouponData) {
          const fromTimestampQuery = action.payload.fromTimestampQuery;

          if (!state.vaixOPCouponData[vaixCode] || !fromTimestampQuery) {
            state.vaixOPCouponData[vaixCode] = action.payload.vaixOPCouponData;
          } else {
            state.vaixOPCouponData[vaixCode] = merge(state.vaixOPCouponData[vaixCode], action.payload.vaixOPCouponData);
          }
          state.vaixOPCouponFromTimestamp[vaixCode] = action.payload.vaixOPCouponFromTimestamp;
        }
      })

      .addCase(loadCouponData.pending, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.codes) {
          return;
        }

        const args = action.meta?.arg;
        const codes = getCouponCodesFromArgs({
          betBuilder: args?.betBuilder,
          cmsMarketFilter: args?.cmsMarketFilter,
          codes: args?.codes,
          featured: args?.featured,
        });

        state.couponLoading[codes] = true;
        state.couponError[codes] = null;
      })
      .addCase(loadCouponData.rejected, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.codes) {
          return;
        }

        const args = action.meta?.arg;
        const codes = getCouponCodesFromArgs({
          betBuilder: args?.betBuilder,
          cmsMarketFilter: args?.cmsMarketFilter,
          codes: args?.codes,
          featured: args?.featured,
        });

        state.couponLoading[codes] = false;
        state.couponError[codes] = action.error.message;
      })
      .addCase(loadCouponData.fulfilled, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.codes) {
          return;
        }

        const args = action.meta?.arg;
        const codes = getCouponCodesFromArgs({
          betBuilder: args?.betBuilder,
          cmsMarketFilter: args?.cmsMarketFilter,
          codes: args?.codes,
          featured: args?.featured,
        });
        const fromTimestampQuery = action.payload.fromTimestampQuery;

        state.couponLoading[codes] = false;

        if (action.payload.locked) {
          // if we are under lockdown, clear this out
          state.couponData[codes] = {};
          state.couponFromTimestamp[codes] = null;
        } else if (action.payload.couponData) {
          if (!state.couponData[codes] || !fromTimestampQuery) {
            state.couponData[codes] = action.payload.couponData;
          } else {
            state.couponData[codes] = merge(state.couponData[codes], action.payload.couponData);
          }
          state.couponFromTimestamp[codes] = action.payload.couponFromTimestamp;
        }
      })

      .addCase(loadVaixCouponData.pending, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.mode) {
          return;
        }

        const vaixCode = `vaix-${action.meta?.arg?.mode}`;
        state.vaixCouponLoading[vaixCode] = true;
        state.vaixCouponError[vaixCode] = null;
      })
      .addCase(loadVaixCouponData.rejected, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.mode) {
          return;
        }

        const vaixCode = `vaix-${action.meta?.arg?.mode}`;
        state.vaixCouponLoading[vaixCode] = false;
        state.vaixCouponError[vaixCode] = action.error.message;
      })
      .addCase(loadVaixCouponData.fulfilled, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.mode) {
          return;
        }

        const vaixCode = `vaix-${action.meta?.arg?.mode}`;
        state.vaixCouponLoading[vaixCode] = false;

        if (action.payload.locked) {
          // if we are under lockdown, clear this out
          state.vaixCouponData[vaixCode] = {};
          state.vaixCouponFromTimestamp[vaixCode] = null;
        } else if (action.payload.vaixCouponData) {
          const fromTimestampQuery = action.payload.fromTimestampQuery;

          if (!state.vaixCouponData[vaixCode] || !fromTimestampQuery) {
            state.vaixCouponData[vaixCode] = action.payload.vaixCouponData;
          } else {
            state.vaixCouponData[vaixCode] = merge(state.vaixCouponData[vaixCode], action.payload.vaixCouponData);
          }
          state.vaixCouponFromTimestamp[vaixCode] = action.payload.vaixCouponFromTimestamp;
        }
      })

      .addCase(loadVaixEventIds.pending, (state, action) => {})
      .addCase(loadVaixEventIds.rejected, (state, action) => {})
      .addCase(loadVaixEventIds.fulfilled, (state, action) => {
        // Note: When`codes` is undefined, no need to add `undefined` key to `couponLoading` and `couponError`.
        if (!action.meta?.arg?.mode) {
          return;
        }

        const vaixCode = `vaix-${action.meta?.arg?.mode}`;

        state.vaixIds[vaixCode] = action.payload.vaixIds;
      })

      .addCase(searchForCouponData.pending, (state) => {
        // state.searchCouponData = null;
        state.searchError = null;
        state.searchLoading = true;
      })
      .addCase(searchForCouponData.rejected, (state, action) => {
        state.searchError = action.error.message;
        state.searchLoading = false;
      })
      .addCase(searchForCouponData.fulfilled, (state, action) => {
        state.searchLoading = false;
        if (action.payload.locked) {
          // if we are under lockdown, clear this out
          state.searchCouponData = {};
          state.searchFromTimestamp = null;
        } else {
          state.searchCouponData = merge(state.searchCouponData ?? {}, action.payload.searchCouponData);
        }
        state.searchFromTimestamp = action.payload.searchFromTimestamp;
      });
  },
  initialState: getInitialState(),
  name: "coupon",
  reducers: {
    clearAsianCoupon(state, action) {
      const codes = action.payload.codes;
      delete state.asianCouponData[codes];
      delete state.asianCouponFromTimestamp[codes];
      delete state.asianCouponError[codes];
    },
    clearCoupon(state, action) {
      const cmsMarketFilter = action.payload.cmsMarketFilter;
      const isBetBuilder = action.payload.betBuilder;

      const codes = action.payload.codes;
      const decoratedCodes = getCouponCodesForTracking(isBetBuilder, false, cmsMarketFilter, codes);

      delete state.couponData[decoratedCodes];
      delete state.couponFromTimestamp[decoratedCodes];
      delete state.couponError[decoratedCodes];
    },
    clearSearchResults(state) {
      state.activeSearchKeyword = null;
      state.searchCouponData = null;
      state.searchFromTimestamp = null;
    },
    clearVaixCoupon(state, action) {
      const vaixCode = `vaix-${action.meta?.arg?.mode}`;

      delete state.vaixCouponData[vaixCode];
      delete state.vaixCouponFromTimestamp[vaixCode];
      delete state.vaixCouponError[vaixCode];
    },
    clearVaixOPCoupon(state, action) {
      const vaixCode = `vaix-${action.meta?.arg?.mode}`;

      delete state.vaixOPCouponData[vaixCode];
      delete state.vaixOPCouponFromTimestamp[vaixCode];
      delete state.vaixOPCouponError[vaixCode];
    },
  },
});
const { actions, reducer } = couponSlice;
export const { clearAsianCoupon, clearCoupon, clearSearchResults, clearVaixCoupon, clearVaixOPCoupon } = actions;
export default reducer;
