import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { isNotEmpty } from "../../utils/lodash";
import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";
import { getAuthAccountId } from "../reselect/auth-selector";

export const getInitialState = () => ({ bonusesByBetslipId: {}, error: null, loading: false });

export const getBetslipBonus = createAsyncThunk("ez/getBetslipBonus", async ({ betslipId }, thunkAPI) => {
  try {
    const { authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const accountId = getAuthAccountId(thunkAPI.getState());

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(
      `/player/acc/${accountId}/ez/betslip/${betslipId}/bonus?originId=${originId}&lineId=${lineId}`,
    );

    return {
      betslipBonus: isNotEmpty(result.data) ? (result.data[0]?.data ?? {}) : {},
      betslipId,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load EZ bonuses for the betslip", // serializable (err.response.data)
      name: "Load EZ Bonus Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const getAllActiveBetslipBonuses = createAsyncThunk("ez/getAllActiveBetslipBonuses", async (data, thunkAPI) => {
  try {
    const { authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const accountId = getAuthAccountId(thunkAPI.getState());

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(
      `/player/acc/${accountId}/ez/betslip/activeBetBonuses?originId=${originId}&lineId=${lineId}`,
    );

    const data = result.data.reduce((accumulator, currentItem) => {
      const betslipId = currentItem?.data?.id;
      if (betslipId) {
        accumulator[betslipId] = currentItem?.data;
      }

      return accumulator;
    }, {});

    return {
      data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load EZ bonuses for the betslip", // serializable (err.response.data)
      name: "Load EZ Bonus Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const ezBonusSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getBetslipBonus.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getBetslipBonus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getBetslipBonus.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.bonusesByBetslipId[action.payload.betslipId] = action.payload.betslipBonus;
      })

      .addCase(getAllActiveBetslipBonuses.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getAllActiveBetslipBonuses.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getAllActiveBetslipBonuses.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        Object.values(action.payload.data)?.forEach((item) => {
          const betslipId = item.id;
          state.bonusesByBetslipId[betslipId] = item;
        });
      });
  },
  initialState: getInitialState(),
  name: "ezBonus",
  reducers: {
    //
  },
});
const { actions, reducer } = ezBonusSlice;
export default reducer;
